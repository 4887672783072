import React, { useEffect, useState, useRef } from 'react';
import { navigate } from 'gatsby';
import InfiniteScroll from 'react-infinite-scroll-component';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

import HeaderBar from 'components/header-bar';
import RecommendItem from 'components/recommend-item';

import { useAPI } from 'utils/api/index.jsx';
import { logGetRecommendData } from 'utils/_omsLog';

const Content = styled.div`
  background-color: #f2f2f2;
  padding: 70px 16px 16px;
  min-height: calc(100% - 55px);
  box-sizing: border-box;

  .result-title {
    font-family: Noto Sans TC;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    color: #333333;
    margin-bottom: 8px;
  }

  .result-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 8px;
    column-gap: 8px;
  }

  .no-result-information {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    box-sizing: border-box;
    width: 80%;
  }
`;

const SearchProductPreview = () => {
  const viewWidth = document.body.clientWidth;
  const mainWidth = 390;
  const ratio = viewWidth / mainWidth;
  return (
    <>
      <ContentLoader
        viewBox={`0 0 ${358 * ratio} 1000`}
        height={1000}
        width={358 * ratio}
        backgroundColor="#FFFFFF"
        foregroundColor="#dedede"
      >
        {[...Array(5)].map((i, index) => {
          return (
            <>
              {[...Array(3)].map((j, subIndex) => {
                return (
                  <>
                    <rect
                      x={subIndex * 127 * ratio}
                      y={20 + 220 * index}
                      rx="8"
                      ry="8"
                      width={105 * ratio}
                      height="140"
                    />
                    <rect
                      x={subIndex * 127 * ratio}
                      y={170 + 220 * index}
                      rx="0"
                      ry="0"
                      width={30 * ratio}
                      height="16"
                    />
                    <rect
                      x={subIndex * 127 * ratio}
                      y={190 + 220 * index}
                      rx="0"
                      ry="0"
                      width={105 * ratio}
                      height="16"
                    />
                    <rect
                      x={subIndex * 127 * ratio}
                      y={210 + 220 * index}
                      rx="0"
                      ry="0"
                      width={80 * ratio}
                      height="16"
                    />
                  </>
                );
              })}
            </>
          );
        })}
      </ContentLoader>
    </>
  );
};

const Recommend = () => {
  const api = useAPI();
  const resultList = useRef();

  const [showPage, setShowPage] = useState(false);
  const [recommendList, setRecommendList] = useState([]);

  const fetchRecommendItemsList = () => {
    setRecommendList([]);
    api
      .getRecommendItems()
      .then((res) => {
        logGetRecommendData(res);
        setShowPage(true);
        setRecommendList(res.rows);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchRecommendItemsList();
  }, []);

  return (
    <>
      <HeaderBar
        title="推薦商品"
        subtitle=""
        fixed
        goBack={() => navigate(-1)}
      />

      <Content>
        {showPage ? (
          <div>
            {recommendList.length > 0 && (
              <InfiniteScroll
                ref={resultList}
                dataLength={recommendList.length}
                next={() => fetchRecommendItemsList()}
                hasMore={false}
                style={{ height: '100%', overflow: 'unset' }}
                loader={<h6>資料載入中...請稍候</h6>}
              >
                <div className="result-list">
                  {recommendList?.map((product, index) => (
                    <RecommendItem
                      data={product}
                      key={`${index}-${product.productId}`}
                    />
                  ))}
                </div>
              </InfiniteScroll>
            )}
          </div>
        ) : !showPage ? (
          <SearchProductPreview />
        ) : undefined}
      </Content>
    </>
  );
};

export default Recommend;
