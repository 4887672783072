import { Axios } from 'api';
import { convertDate2StringDateTime } from 'utils/_dateHandler';

const url = process.env.GATSBY_BASE_API_URL;
const axios = new Axios({
  baseURL: url,
  timeout: 60,
  recallOn: false,
  recallTimes: 3,
  shouldReturnCode: true,
});

const logGetRecommendData = (res) => {
  let prodData = [];
  res.rows.forEach((element) => {
    prodData.push({
      name: element.productName,
      prd_id: element.productId,
      prd_order: element.prd_order,
    });
  });
  const params = {
    rec_id: res.recId,
    uid: res.ocaUuid,
    rec_prd: prodData,
  };
  axios
    .post('v1/qc/oms/cartjsprecimpression', params)
    .then((response) => {})
    .catch((error) => {
      reject(error);
    });
};

const logRecommendAddCart = (recommendData, productData) => {
  const params = {
    rec_id: recommendData.recId,
    uid: recommendData.ocaUuid,
    prd_id: productData.productId,
    prd_name: productData.productName,
    prd_order: productData.prd_order,
    model_id: recommendData.modelCode,
    action: 0,
  };
  axios
    .post('v1/qc/oms/cartjsprecclick', params)
    .then((response) => {})
    .catch((error) => {
      reject(error);
    });
};

const logBQ = (params) => {
  console.log('logBQ', params);
  axios
    .post('v1/qc/oms/logjsprec', params)
    .then((response) => {})
    .catch((error) => {});
};

export { logGetRecommendData, logRecommendAddCart, logBQ };
